<template>
  <LexmeaModal class="w-full" :open="true" @close="emits('close')">
    <template #title>
      <p>Studiere nachhaltig</p>
    </template>
    <template #default>
      <table class="w-full border-b-4 border-lexmea-blue-500 text-xs">
        <thead class="bg-lexmea-blue-300 text-center text-white">
          <tr class="text-base font-bold">
            <th class="!text-center">Textsammlung</th>
            <th class="!text-center">Art</th>
            <th class="!text-center">Seiten</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th class="bg-blue-100">DTV Textsammlung Strafgesetzbuch</th>
            <td>Gebundenes Werk</td>
            <td>357</td>
          </tr>
          <tr>
            <th>DTV Basistexte Öffentliches Recht</th>
            <td>Gebundenes Werk</td>
            <td>891</td>
          </tr>
          <tr>
            <th class="bg-blue-100">
              DTV Textsammlung Bürgerliches Gesetzbuch
            </th>
            <td>Gebundenes Werk</td>
            <td>911</td>
          </tr>
          <tr>
            <th>DTV Textsammlung Arbeitsgesetze</th>
            <td>Gebundenes Werk</td>
            <td>1,019</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>Loseblattsammlung</td>
            <td>4,690</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>EL 186 (12/2021)</td>
            <td>910</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>EL 185</td>
            <td>1,080</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>EL 184</td>
            <td>400</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>EL 183</td>
            <td>320</td>
          </tr>
          <tr>
            <th class="bg-blue-100">Schönfelder / Habersack</th>
            <td>EL 182</td>
            <td>880</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>EL 181</td>
            <td>300</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>EL 180</td>
            <td>480</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>EL 179</td>
            <td>500</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>EL 178 (01/2020)</td>
            <td>1,060</td>
          </tr>
          <tr>
            <th></th>
            <td>Loseblattsammlung</td>
            <td>4,792</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 141 (11/2021)</td>
            <td>220</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 140</td>
            <td>300</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 139</td>
            <td>320</td>
          </tr>
          <tr>
            <th>Ziegler / Tremel</th>
            <td>EL 138</td>
            <td>420</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 137</td>
            <td>160</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 136</td>
            <td>300</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 135</td>
            <td>200</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 134 (02/2020)</td>
            <td>440</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>Loseblattsammlung</td>
            <td>3,480</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>El 69 (10/2021)</td>
            <td>210</td>
          </tr>
          <tr>
            <th class="bg-blue-100">Sartorius II</th>
            <td>EL 68</td>
            <td>220</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>EL 67</td>
            <td>270</td>
          </tr>
          <tr>
            <th class="bg-blue-100"></th>
            <td>EL 66 (05/2020)</td>
            <td>380</td>
          </tr>
          <tr>
            <th></th>
            <td>Loseblattsammlung</td>
            <td>5,064</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 132 (11/2021)</td>
            <td>940</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 131</td>
            <td>880</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 130</td>
            <td>450</td>
          </tr>
          <tr>
            <th>Sartorius I</th>
            <td>EL 129</td>
            <td>800</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 128</td>
            <td>400</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 127</td>
            <td>690</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 126</td>
            <td>270</td>
          </tr>
          <tr>
            <th></th>
            <td>EL 125 (01/2020)</td>
            <td>1,050</td>
          </tr>
          <tr class="text-base font-bold text-lexmea-blue-500">
            <th>Gesamt</th>
            <td></td>
            <td class="pt-2">36,054</td>
          </tr>
        </tbody>
      </table>
    </template>
  </LexmeaModal>
</template>

<script setup lang="ts">
const emits = defineEmits<{
  close: [];
}>();
</script>

<style lang="scss" scoped>
th {
  @apply pl-2;
}
td:nth-child(3) {
  @apply text-right;
}

th:nth-child(1) {
  @apply text-left;
}

tr:nth-child(odd) {
  td {
    @apply bg-blue-100;
  }
}
</style>
