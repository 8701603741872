<template>
  <BackgroundPatternWrapper>
    <NavBarSecondary />
    <LandingPageHeaderContainer>
      <template #title>
        <h1>
          Dein personalisierbares<br />
          Online-Gesetzbuch.
        </h1>
      </template>
      <template #secondary_title>Jura. Einfach. Digital.</template>
      <template #button>
        <TrialButton white />
      </template>
    </LandingPageHeaderContainer>
    <ProductContentContainer class="container_skew">
      <SectionProduct />
      <SectionFunctionalities />
      <SectionAdvantages />
      <SectionTestimonials />
      <SectionDataOwnership />
    </ProductContentContainer>
    <FooterContainer />
  </BackgroundPatternWrapper>
</template>
