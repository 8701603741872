<template>
  <div>
    <h2
      ref="testimonialsHeading"
      v-motion-lexmea-slide-top
      class="cards-testimonials px-3 text-4xl font-extrabold text-lexmea-blue-400"
    >
      Meinungen.
    </h2>
    <p
      v-motion-lexmea-slide-top
      class="relative z-10 mb-5 px-3 text-base text-lexmea-blue-500 md:text-xl"
    >
      Was andere über LexMea sagen:
    </p>

    <div class="mb-24 mt-12 flex justify-center px-3 md:mt-28">
      <div class="flex h-full flex-col-reverse gap-10 md:flex-row md:gap-16">
        <figure>
          <img
            loading="lazy"
            class="w-56"
            :src="thomasIcon"
            alt="Bild von Thomas Wischmeyer"
          />
          <figcaption class="select-none text-4.5pt text-gray-300">
            Foto von Marzena Skubatz
          </figcaption>
        </figure>
        <div class="flex flex-col justify-between">
          <p
            class="quote-background mb-8 text-lg font-medium italic text-lexmea-blue-300 md:mb-0 md:text-2xl"
          >
            Zentral für eine gute Klausurlösung
            <br />
            ist die Verknüpfung von Norm und Wissen.
            <br />
            Dabei hilft LexMea.
          </p>

          <div class="text-lexmea-blue-200 md:mb-5">
            <div class="font-bold">Prof. Dr. Thomas Wischmeyer</div>
            <div class="text-sm font-medium">
              Professor für Öffentliches Recht und Recht der Digitalisierung
            </div>
          </div>
        </div>
      </div>
    </div>
    <SponsorLogos></SponsorLogos>
  </div>
</template>

<script setup lang="ts">
import thomasIcon from "~/assets/landingpage/testimonials/prof-1.webp";

const testimonialsHeading = ref<HTMLElement>();

const { cardsImageCSSTopValue } = useProductCardsScrollParallax(
  -35,
  testimonialsHeading,
  1.1
);
</script>

<style lang="scss" scoped>
.quote-background {
  z-index: 10;
  position: relative;

  &::before {
    content: "”";
    color: #dfeef5;
    position: absolute;
    display: inline-block;
    z-index: -1;
    top: 30px;
    transform: scale(10);
  }
}

.cards-testimonials {
  z-index: 10;
  position: relative;
  padding-right: 47px;

  &::before {
    content: url("~/assets/landingpage/bg-vectors/cards-funktionen.svg");
    position: absolute;
    display: inline-block;
    top: v-bind(cardsImageCSSTopValue);
    left: -8px;
    z-index: -1;
  }
}
</style>
