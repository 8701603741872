<template>
  <div>
    <div>
      <h2
        ref="funktionenHeading"
        v-motion-lexmea-slide-top
        class="cards-funktionen px-3 text-4xl font-extrabold text-lexmea-blue-400 md:text-[40px]"
      >
        Funktionen.
      </h2>
      <p
        v-motion-lexmea-slide-top
        class="relative z-10 mb-8 px-3 text-base text-lexmea-blue-500 md:text-xl"
      >
        Personalisiere dein Gesetzbuch.
      </p>

      <FunctionVideo
        v-motion-lexmea-slide-left
        class="mb-32"
        videoName="%2323-02-23%20Markieren%20720p_compressed"
        posterFileName="funktionen-video-poster-1.jpg"
      >
        <template #title>Unterstreichen,<br />Markieren & Verlinken</template>
        <template #description
          >Erkenne auf einen Blick,<br />wo etwas Wichtiges in den Normen
          steckt!
        </template>
      </FunctionVideo>

      <FunctionVideo
        v-motion-lexmea-slide-right
        class="mb-32"
        :reverse="true"
        videoName="%2323-02-23%20Notizen%20720p%20ohne%20Zoom_compressed"
        posterFileName="funktionen-video-poster-2.jpg"
      >
        <template #title>Notizen und<br />Definitionen anlegen</template>
        <template #description
          >Mache deine Notizen direkt am Gesetz:<br />Anmerkungen, Definitionen
          und alles was du dir schnell merken willst!
        </template>
      </FunctionVideo>

      <FunctionVideo
        v-motion-lexmea-slide-left
        class="mb-32"
        videoName="%2323-02-23%20Schemata%20720p_compressed"
        posterFileName="funktionen-video-poster-3.jpg"
      >
        <template #title>Schemata ansehen<br />oder eigene erstellen</template>
        <template #description>
          Sieh dir zu allen wichtigen Normen Schemata von LexMea an oder
          verfasse deine eigenen!
        </template>
      </FunctionVideo>
    </div>
    <div class="mx-auto w-full">
      <TrialButton />
    </div>
  </div>
</template>
<script setup lang="ts">
const funktionenHeading = ref<HTMLElement>();

const { cardsImageCSSTopValue } = useProductCardsScrollParallax(
  -35,
  funktionenHeading,
  1.1
);
</script>
<style lang="scss" scoped>
.cards-funktionen {
  z-index: 10;
  position: relative;
  padding-right: 47px;

  &::before {
    content: url("~/assets/landingpage/bg-vectors/cards-funktionen.svg");
    position: absolute;
    display: inline-block;
    top: v-bind(cardsImageCSSTopValue);
    left: -8px;
    z-index: -1;
  }
}
</style>
