<template>
  <LexmeaModal :noPaddingY="true" :open="open" @close="$emit('close')">
    <template #title>
      <span>Willkommen Early Adopter!</span>
    </template>
    <template #default>
      <div class="pt-5 text-justify">
        <p>
          Mit LexMea bringst du dein juristisches Lernen und Arbeiten ins
          digitale Zeitalter. Nach einer umfangreichen geschlossenen
          <strong>Alpha-Testphase</strong> ist unsere App jetzt bereit für dich.
          In der jetzigen <strong>Beta-Phase</strong> sind wir besonders dankbar
          für Feedback:
        </p>

        <ul class="mt-2">
          <li>Was fehlt dir?</li>
          <li>Was können wir verbessern?</li>
          <li>Was gefällt dir besonders gut?</li>
        </ul>
        <p class="mt-3">
          Klicke
          <button class="underline" @click="openFeedbackDialog()">hier</button>
          oder jederzeit rechts unten auf
          <strong>„Feedback“</strong>, um uns deine Gedanken mitzuteilen.
        </p>
      </div>
    </template>
  </LexmeaModal>
</template>

<script setup lang="ts">
const emits = defineEmits<{
  close: [];
}>();

defineProps<{
  open: boolean;
}>();

function openFeedbackDialog() {
  useFeedbackDialogStore().open = true;
  emits("close");
}
</script>
