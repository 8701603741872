<template>
  <div class="flex justify-around gap-8 overflow-x-auto p-5">
    <a
      v-for="(sponsor, index) in sponsors"
      :key="index"
      class="block flex-shrink-0 opacity-75 hover:opacity-100"
      :href="sponsor.url"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <img :src="sponsor.logo" :alt="sponsor.name" class="h-8" />
    </a>
  </div>
</template>

<script setup lang="ts">
import sponsor1 from "~/assets/landingpage/sponsors/juristischedatenbanken.de.svg";
import sponsor2 from "~/assets/landingpage/sponsors/legalexo.svg";
import sponsor3 from "~/assets/landingpage/sponsors/legaltech.svg";
import sponsor4 from "~/assets/landingpage/sponsors/legaltechverzeichnis.svg";
import sponsor5 from "~/assets/landingpage/sponsors/juraarchiv.svg";

interface Sponsor {
  name: string;
  url: string;
  logo: string;
}

const sponsors: Sponsor[] = [
  {
    name: "legaltechverzeichnis",
    url: "https://legal-tech-verzeichnis.de/legal-tech-nachrichten/neues-online-gesetzbuch-lexmea-mit-zahlreichen-bearbeitungsfunktionen/",
    logo: sponsor4,
  },
  {
    name: "juristischedatenbanken",
    url: "https://www.juristischedatenbanken.de/kostenlose-juristische-datenbanken/",
    logo: sponsor1,
  },
  {
    name: "legalexo",
    url: "https://www.legalexo.de/blog/das-gesetzbuch-online-effektiv-nutzen",
    logo: sponsor2,
  },
  {
    name: "legal-tech",
    url: "https://legal-tech.de/juristische-datenbanken/",
    logo: sponsor3,
  },
  {
    name: "jura archiv",
    url: "https://www.juraarchiv.de/gesetzestexte/",
    logo: sponsor5,
  },
];
</script>
