<template>
  <div
    class="relative mb-36 mt-8 flex min-h-[10vh] flex-col items-center md:mb-[20rem] md:mt-12 lg:mb-72"
  >
    <span
      class="mb-1 text-center text-2xl font-bold leading-normal text-white md:mb-3 md:text-[41px]"
    >
      <slot name="title"></slot>
    </span>

    <div
      class="absolute top-20 flex w-20 justify-center place-self-start rounded-r-md bg-lexmea-gray-200 py-1 font-bold tracking-wide text-lexmea-blue-900 md:w-32 md:py-2"
    >
      <span class="sm:text-lg md:text-xl">BETA</span>
      <div
        class="relative top-1 ml-1 flex h-4 w-4 cursor-pointer justify-center rounded-full border border-lexmea-blue-900 text-xs"
        @click="openBetabannerModal"
      >
        <span class="-mt-[.05rem]">i</span>
      </div>
    </div>

    <span class="mb-10 text-center text-base text-white md:text-[29px]">
      <slot name="secondary_title"></slot>
    </span>
    <slot name="button"></slot>
  </div>

  <BetaBannerModal :open="open" @close="open = false" />
</template>

<script lang="ts" setup>
const open = ref(false);

const openBetabannerModal = () => {
  open.value = true;
};
</script>
