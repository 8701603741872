<template>
  <div class="mx-auto w-full">
    <h2
      ref="dataOwnershipHeading"
      v-motion-lexmea-slide-top
      class="cards-finanz px-3 text-4xl font-extrabold text-lexmea-blue-400 md:text-[40px]"
    >
      Datensouveränität.
    </h2>
    <p
      v-motion-lexmea-slide-top
      class="relative z-10 mb-8 px-3 text-base text-lexmea-blue-500 md:text-xl"
    >
      Wir machen kein Geld mit deinen Daten.
    </p>
    <div
      class="relative z-10 mx-auto mb-16 max-w-[535px] rounded-3xl bg-[#597A87] p-8 text-white xl:max-w-none xl:gap-20"
    >
      <div class="mx-auto flex w-fit flex-col gap-8 xl:w-full xl:flex-row">
        <div class="flex w-fit items-center gap-4 lg:w-full xl:justify-center">
          <img
            loading="lazy"
            class="h-10 w-10 object-contain md:h-12 md:w-12 2xl:h-[88px] 2xl:w-[88px]"
            :src="fin3Icon"
            alt="LexMea Datenschutz"
          />
          <p class="w-[250px] text-sm text-white md:text-base">
            Deine
            <a href="/datenschutzhinweis" class="no-underline">Daten</a>
            verkaufen wir nie! <br />
            Die bleiben sicher -<br />
            auf europäischen Servern.
          </p>
        </div>
        <div class="flex w-fit items-center gap-4 xl:w-full xl:justify-center">
          <img
            loading="lazy"
            class="h-10 w-10 object-contain md:h-12 md:w-12 2xl:h-[88px] 2xl:w-[88px]"
            :src="fin1Icon"
            alt="LexMea kostenlos"
          />

          <p class="w-[255px] text-sm text-white md:text-base">
            Alle Grundfunktionen von LexMea <br />
            sind für Dich zu jedem Zeitpunkt <br class="lg:hidden" />
            <strong class="text-3xl font-extrabold md:text-4xl"
              >KOSTENLOS.</strong
            >
          </p>
        </div>
        <div class="flex w-fit items-center gap-4 lg:w-full xl:justify-center">
          <img
            loading="lazy"
            class="h-10 w-10 object-contain md:h-12 md:w-12 2xl:h-[88px] 2xl:w-[88px]"
            :src="fin2Icon"
            alt="LexMea Stellenanzeigen"
          />
          <p class="w-[250px] text-sm text-white md:text-base">
            LexMea wird sich langfristig <br />
            über juristische Stellenanzeigen<br />
            und Repetitorien finanzieren.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import fin1Icon from "~/assets/landingpage/data-ownership/fin1.svg";
import fin2Icon from "~/assets/landingpage/data-ownership/fin2.svg";
import fin3Icon from "~/assets/landingpage/data-ownership/fin3.svg";

const dataOwnershipHeading = ref();
const { cardsImageCSSTopValue } = useProductCardsScrollParallax(
  -30,
  dataOwnershipHeading,
  3
);
</script>
<style lang="scss" scoped>
.cards-finanz {
  z-index: 10;
  position: relative;

  &::before {
    content: url("~/assets/landingpage/bg-vectors/cards-finanz.svg");
    position: absolute;
    display: inline-block;
    top: v-bind(cardsImageCSSTopValue);
    left: -10px;
    z-index: -10;
  }
}
</style>
